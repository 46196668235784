import React from 'react'
import { ShellProps } from '../src/types/shell'
import TDELayout from '../src/components/index'
import projectNames from '../src/configs/projectNames'
import { Stack } from '../src/types/stratus'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({ stack = Stack.pie, properties, children }) {
  const {
    v1: { navigation, localization, featureFlags, analytics }
  } = window.Shell as ShellProps

  const { contentStackCredential, compatibilityDefinition } = properties

  const userAgent = navigator.userAgent

  // You can find the "stack" information by props
  // const stack = props.stack.
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <TDELayout
        analytics={analytics}
        stack={stack}
        navigation={navigation}
        localization={localization}
        featureFlags={featureFlags}
        contentStackCredential={contentStackCredential}
        userAgent={userAgent}
        compatibilityDefinition={compatibilityDefinition}
      >
        {children}
      </TDELayout>
    </section>
  )
}
