import React from 'react'
import BackButton from './BackButton'
import NextButton from './NextButton'
import classes from './FooterNavigation.module.scss'

const FooterNavigation = () => {
  return (
    <div className={classes.footerNavigation} data-testid="footer">
      <footer data-testid="footer-navigation">
        <BackButton />
        <NextButton />
      </footer>
    </div>
  )
}

export default FooterNavigation
