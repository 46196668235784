import React, { useContext, useEffect } from 'react'
import Button from '@veneer/core/dist/esm/scripts/button'
import { ConfigContext } from '../store/ConfigContext'
import { VENEER_SECONDARY_BUTTON_APPEARANCE } from '../store/Constants'
import useAnalytics from '../hooks/useAnalytics'
import { ANALYTICS } from '../store/AnalyticsConstants'
import { getActionAuxParamsForAnalytics } from '../utils/Utils'

const BackButton = ({
  appearance = VENEER_SECONDARY_BUTTON_APPEARANCE,
  dataTestId = 'Back'
}) => {
  const { states, footer, sku, paas, localization, os } = useContext(
    ConfigContext
  )
  const { fireControlDisplayed, fireButtonClick } = useAnalytics(
    ANALYTICS.SCREENS.FOOTER_NAVIGATION
  )
  const prevRoute = () => {
    fireButtonClick(ANALYTICS.BUTTONS.BACK, {
      detail: window.location.pathname,
      actionAuxParams: getActionAuxParamsForAnalytics(
        sku,
        paas,
        localization.country,
        localization.language,
        os
      )
    })
    footer.updatePreviousRoute()
  }

  useEffect(() => {
    if (states.footer.backVisible) {
      fireControlDisplayed(ANALYTICS.BUTTONS.BACK, {
        detail: window.location.pathname,
        actionAuxParams: getActionAuxParamsForAnalytics(
          sku,
          paas,
          localization.country,
          localization.language,
          os
        )
      })
    }
  }, [window.location.pathname])

  return (
    states.footer.backVisible && (
      <Button
        appearance={appearance}
        data-testid={dataTestId}
        disabled={states.footer.backDisabled}
        onClick={prevRoute}
      >
        {states.footer.backButtonLabel}
      </Button>
    )
  )
}

export default BackButton
