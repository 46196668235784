const BASE_PATH = '/TdeLayout/'

const ACTIVITY = {
  HARDWARE_SETUP: 'HardwareSetup-v01'
}

const BUTTONS = {
  NEXT: 'Next',
  BACK: 'Back',
  HOMEPAGE_HP_LOGO: 'HpLogo',
  HOMEPAGE_PRINTER_NAME: 'PrinterName'
}

const LINKS = {
  SUPPORT_INSTRUCTIONS: 'SupportInstructions',
  PRIVACY_FOOTER: 'PrivacyFooter'
}

const SCREENS = {
  SETUP_TAB: {
    screenName: 'SetupTab',
    activity: ACTIVITY.HARDWARE_SETUP,
    screenPath: BASE_PATH
  },
  LEARN_TAB: {
    screenName: 'LearnTab',
    activity: ACTIVITY.HARDWARE_SETUP,
    screenPath: BASE_PATH
  },
  GET_HELP_TAB: {
    screenName: 'GetHelpTab',
    activity: ACTIVITY.HARDWARE_SETUP,
    screenPath: BASE_PATH
  },
  FOOTER_NAVIGATION: {
    screenName: 'FooterNavigation',
    activity: ACTIVITY.HARDWARE_SETUP,
    screenPath: BASE_PATH
  },
  PRIVACY_FOOTER: {
    screenName: 'PrivacyFooter',
    activity: ACTIVITY.HARDWARE_SETUP,
    screenPath: BASE_PATH
  },
  HEADER_NAVIGATION: {
    screenName: 'HeaderNavigation',
    activity: ACTIVITY.HARDWARE_SETUP,
    screenPath: BASE_PATH
  }
}

const SCREEN_BY_SUBTAB = [
  SCREENS.SETUP_TAB,
  SCREENS.LEARN_TAB,
  SCREENS.GET_HELP_TAB
]

export const ANALYTICS = {
  ACTIVITY,
  BUTTONS,
  LINKS,
  SCREENS,
  SCREEN_BY_SUBTAB,
  VERSION: '1.4.0',
  ENVELOPE_VERSION: '1.4.0',
  EVENT_DETAIL_TYPE:
    'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
  EVENT_CATEGORY: 'simpleUi'
}
