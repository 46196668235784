export const SIDEBAR_ACTIONS = {
  VISIBILITY: 1,
  CUSTOM_SIDEBAR: 2
}

export const sidebarReducer = (state, action) => {
  switch (action.type) {
    case SIDEBAR_ACTIONS.VISIBILITY:
      return { ...state, sidebarVisible: action.visible }
    case SIDEBAR_ACTIONS.CUSTOM_SIDEBAR:
      return { ...state, customSidebar: action.sidebar }
  }
}
