import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import classes from './Sidebar.module.scss'
import { ConfigContext } from '../store/ConfigContext'
import IconCheckmark from '@veneer/core/dist/esm/scripts/icons/icon_checkmark'
import { ContentStackTypes } from '../store/Constants'
import VeneerStepper from '@veneer/core/dist/scripts/stepper'

const setLabel = (label) => {
  return (
    <p
      dangerouslySetInnerHTML={{
        __html: label
      }}
    />
  )
}

const SIDEBAR_EXPERIENCE = 'OC'

const Sidebar = () => {
  const { states, footer, sidebar, paas, pageDataCache } =
    useContext(ConfigContext)
  const [steps, setSteps] = useState([])
  const { footer: footerState, sidebar: sidebarState } = states
  const [pageData, setPageData] = useState(null)

  useEffect(() => {
    const tdeLayoutPageData = pageDataCache?.get(
      ContentStackTypes.tde_layout.content_type_uid
    )
    if (tdeLayoutPageData?.sidebar) {
      setPageData(tdeLayoutPageData.sidebar)
    }
  }, [pageDataCache])

  useEffect(() => {
    if (!pageData) return
    const sidebarSteps = pageData || []

    const currentCustomNavigation = footerState.customNavigation

    if (
      sidebarSteps.length === 0 ||
      currentCustomNavigation?.keys().length === 0
    ) {
      return
    }
    const copy = new Map(sidebarSteps.map((x) => [x.identifier, x.label]))

    let customSidebar = [
      {
        label: setLabel(copy.get('paper')),
        ids: ['load-paper']
      },
      {
        label: setLabel(copy.get('ink')),
        ids: ['install-ink']
      },
      {
        label: setLabel(copy.get('alignment')),
        ids: ['alignment']
      },
      currentCustomNavigation?.has('printer-use') &&
        paas && {
          label: setLabel(copy.get('printer_use')),
          ids: ['printer-use']
        },
      currentCustomNavigation?.has('setup-checklist') &&
        paas && {
          label: setLabel(copy.get('setup_checklist')),
          ids: [
            'setup-checklist',
            'connect-usb',
            'select-usb-on-display',
            'install-driver',
            'driver-not-found',
            'connect-printer-to-network',
            'skip-internet-connection'
          ]
        },
      {
        label: setLabel(copy.get('hp_software')),
        ids: [
          'hp-software',
          'finish-setup-business',
          'install-driver-intranet',
          'driver-not-found-intranet',
          'start-airprint'
        ]
      }
    ]

    customSidebar = customSidebar
      .filter((step) => step)
      .map((step, index) => {
        step.step = index + 1
        return { ...step }
      })

    sidebar.setCustomSidebar(customSidebar)
  }, [footerState, pageData, paas])

  useEffect(() => {
    if (!sidebarState.customSidebar && !footerState.currentRoute) return
    let stepperData = []
    let currentStepOrder
    const sidebarLength = Object.values(sidebarState.customSidebar || {}).length

    Object.values(sidebarState.customSidebar || {}).forEach((entry, index) => {
      const lastStep = entry.step === sidebarLength
      if (entry.ids.includes(footerState.currentRoute.key)) {
        currentStepOrder = entry.step
        stepperData.push({
          label: entry.label,
          step: entry.step,
          status: 'current'
        })
      } else if (
        currentStepOrder === undefined ||
        entry.step < currentStepOrder
      ) {
        stepperData.push({
          label: (
            <span
              className={classes.label}
              onClick={() => stepperClickHandler(null, index)}
            >
              {entry.label}
            </span>
          ),
          step: lastStep ? entry.step : <IconCheckmark size={26} />,
          status: lastStep ? 'current' : 'complete'
        })
      } else if (entry.step > currentStepOrder) {
        stepperData.push({
          label: entry.label,
          step: entry.step,
          status: 'incomplete'
        })
      }
    })
    setSteps(stepperData)
  }, [footerState.currentRoute, sidebarState.customSidebar])

  const stepperClickHandler = useCallback(
    (_, index) => {
      //only completed steps are allowed to be clicked
      if (steps[index].status !== 'complete') return

      const targetRouteKey = sidebarState.customSidebar[index].ids[0]
      footer.updateCurrentRoute({ key: targetRouteKey })
    },
    [steps, footer, sidebarState.customSidebar]
  )

  const sidebarData = useMemo(() => {
    return {
      experience: SIDEBAR_EXPERIENCE,
      customSidebar: steps,
      stepperClickHandler
    }
  }, [steps, stepperClickHandler])

  if (!sidebarData) return <></>

  return (
    <div className={classes.stepper} id="stepper">
      <VeneerStepper
        direction="vertical"
        steps={steps}
        interactive={!!stepperClickHandler}
        onChange={stepperClickHandler}
      />
    </div>
  )
}

export default Sidebar
