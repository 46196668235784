export enum Stack {
  // eslint-disable-next-line no-unused-vars
  dev,
  // eslint-disable-next-line no-unused-vars
  pie,
  // eslint-disable-next-line no-unused-vars
  stage,
  // eslint-disable-next-line no-unused-vars
  prod
}
