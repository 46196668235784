export const FOOTER_ACTIONS = {
  VISIBILITY: 1,
  NAVIGATION: 2,
  UPDATE: 3,
  NEXT: 4,
  PREVIOUS: 5,
  CURRENT_ROUTE: 6,
  NEXT_DISABLED: 7,
  NEXT_VISIBLE: 8,
  NEXT_LABEL: 9,
  BACK_DISABLED: 10,
  BACK_VISIBLE: 11,
  BACK_LABEL: 12
}

const changeCurrentRoute = (state, route, updateNav = true) => {
  const routeOption = { ...state.customNavigation.get(route.key) }
  const newState = {
    currentRoute: { ...routeOption, ...route }
  }
  if (route.path) {
    newState.currentRoute = route
  }

  if (state.customNavigation && state.customNavigation?.size > 0 && updateNav) {
    const currentNav = state.customNavigation
    if (!route.path) {
      route = {
        ...routeOption,
        ...route
      }
    }
    currentNav.set(route.key, { key: route.key, ...route })
    newState['customNavigation'] = currentNav
  }
  return newState
}

const getCustomNavigation = (state, navigation) => {
  const newState = { customNavigation: navigation }
  if (navigation.size <= 0) return newState
  if (Object.keys(state.currentRoute).length <= 0) {
    for (let entry of navigation) {
      const [k, v] = entry
      if (window.location.pathname?.indexOf(v.path) > -1) {
        newState['currentRoute'] = { key: k, ...v }
        return newState
      }
    }
    // No matching path, default to first entry
    const [k, v] = navigation.entries().next().value
    newState['currentRoute'] = { key: k, ...v }
  } else {
    const currentRoute = state.currentRoute
    const routeOption = navigation.get(currentRoute.key)
    //check if the current route is in the navigation map
    if (!routeOption) {
      //if not, default to first entry
      const [k, v] = navigation.entries().next().value
      newState['currentRoute'] = { key: k, ...v }
      return newState
    }
  }
  return newState
}

const getNextRoute = (state, option) => {
  let routeOption
  const routeMap = state.customNavigation
  if (option) routeOption = option
  else if (state.currentRoute?.next) {
    const key = state.currentRoute.next
    const nextRouteInMap = routeMap.get(key)
    if (!nextRouteInMap) {
      window.location.assign(key)
      return {}
    }
    routeOption = { key, ...routeMap.get(key) }
  }
  return changeCurrentRoute(state, routeOption, false)
}

const getPreviousRoute = (state, option) => {
  let routeOption
  const routeMap = state.customNavigation
  if (option) routeOption = option
  else if (state.currentRoute?.previous) {
    const key = state.currentRoute.previous
    routeOption = { key, ...routeMap.get(key) }
  }
  return changeCurrentRoute(state, routeOption, false)
}

export const footerReducer = (state, action) => {
  switch (action.type) {
    case FOOTER_ACTIONS.NAVIGATION:
      return { ...state, ...getCustomNavigation(state, action.navigation) }
    case FOOTER_ACTIONS.NEXT:
      return { ...state, ...getNextRoute(state, action.option) }
    case FOOTER_ACTIONS.UPDATE:
      return { ...state, ...changeCurrentRoute(state, action.route) }
    case FOOTER_ACTIONS.PREVIOUS:
      return { ...state, ...getPreviousRoute(state, action.option) }
    case FOOTER_ACTIONS.VISIBILITY:
      return { ...state, footerNavigationVisible: action.visible }
    case FOOTER_ACTIONS.NEXT_VISIBLE:
      return { ...state, nextVisible: action.visible }
    case FOOTER_ACTIONS.NEXT_DISABLED:
      return { ...state, nextDisabled: action.disabled }
    case FOOTER_ACTIONS.NEXT_LABEL:
      return { ...state, nextButtonLabel: action.label }
    case FOOTER_ACTIONS.BACK_VISIBLE:
      return { ...state, backVisible: action.visible }
    case FOOTER_ACTIONS.BACK_DISABLED:
      return { ...state, backDisabled: action.disabled }
    case FOOTER_ACTIONS.BACK_LABEL:
      return { ...state, backButtonLabel: action.label }
  }
}
