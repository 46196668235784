import { useContext } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import { ANALYTICS } from '../store/AnalyticsConstants'
import eventDetailSchema from '../assets/eventDetailSchema.json'
import { Validator } from 'jsonschema'

const validateEvent = (eventDetail) => {
  const validator = new Validator()
  if (validator.validate(eventDetail, eventDetailSchema).valid) {
    return eventDetail
  }
  throw `Analytic event is not valid!\r\n${JSON.stringify(
    eventDetail,
    null,
    '  '
  )}`
}

const buildEventPayload = (action, activity, control, screen) => {
  const eventDetail = {
    action,
    ...(screen.mode ? { screenMode: screen.mode } : {}),
    screenName: screen.name,
    screenPath: screen.path,
    version: ANALYTICS.VERSION,
    ...(activity ? { activity: activity } : {}),
    ...(control.detail ? { controlDetail: control.detail } : {}),
    ...(control.name ? { controlName: control.name } : {}),
    ...(control.actionAuxParams
      ? { actionAuxParams: control.actionAuxParams }
      : {})
  }
  return {
    dateTime: new Date().toISOString(),
    eventDetailType: ANALYTICS.EVENT_DETAIL_TYPE,
    eventCategory: ANALYTICS.EVENT_CATEGORY,
    version: ANALYTICS.ENVELOPE_VERSION,
    eventDetail: validateEvent(eventDetail)
  }
}

function useAnalytics(screen) {
  const { publishCdmEvent } = useContext(ConfigContext)
  const checkScreenValidity = () => {
    if (Object.values(ANALYTICS.SCREENS).indexOf(screen) === -1) {
      throw new Error('Invalid screen')
    }
  }

  const { activity, screenPath, screenName } = screen

  const _buildEvent = (
    action,
    { name, detail, mode, actionAuxParams },
    customScreen
  ) => {
    return buildEventPayload(
      action,
      customScreen?.activity || activity,
      {
        name,
        detail,
        actionAuxParams
      },
      {
        name: customScreen?.screenName || screenName,
        mode,
        path: customScreen?.screenPath || screenPath
      }
    )
  }

  const _fire = (
    action,
    { name, detail, mode, actionAuxParams },
    metadata = null,
    customScreen
  ) => {
    try {
      publishCdmEvent(
        _buildEvent(
          action,
          { name, detail, mode, actionAuxParams },
          customScreen
        ),
        metadata
      )
    } catch (error) {
      console.error(error)
    }
  }

  const fireScreenDisplayed = (props = {}, metadata = null) => {
    checkScreenValidity()
    _fire('ScreenDisplayed', props, metadata)
  }

  const fireCustomScreenDisplayed = (
    customScreen,
    props = {},
    metadata = null
  ) => {
    checkScreenValidity()
    _fire('ScreenDisplayed', props, metadata, customScreen)
  }

  const buildButtonClickEvent = (name, props = {}) => {
    checkScreenValidity()
    if (Object.values(ANALYTICS.BUTTONS).indexOf(name) === -1) {
      throw new Error('Invalid button')
    }
    return _buildEvent('ControlButtonClicked', { name, ...props })
  }

  const fireButtonClick = (name, props = {}) => {
    publishCdmEvent(buildButtonClickEvent(name, props))
  }

  const fireControlDisplayed = (name, props = {}) => {
    checkScreenValidity()
    if (Object.values(ANALYTICS.BUTTONS).indexOf(name) === -1) {
      throw new Error('Invalid button')
    }
    _fire('ControlDisplayed', { name, ...props })
  }

  const fireToggleClick = (name, enabled, props = {}) => {
    checkScreenValidity()
    if (Object.values(ANALYTICS.BUTTONS).indexOf(name) === -1) {
      throw new Error('Invalid toggle')
    }
    _fire(enabled ? 'ControlToggleEnabled' : 'ControlToggleDisabled', {
      name,
      ...props
    })
  }

  const buildHyperLinkClickEvent = (name, props = {}) => {
    checkScreenValidity()
    return _buildEvent('ControlHyperLinkClicked', { name, ...props })
  }

  const fireHyperLinkClick = (name, props = {}) => {
    publishCdmEvent(buildHyperLinkClickEvent(name, props))
  }

  return {
    buildButtonClickEvent,
    buildHyperLinkClickEvent,
    fireScreenDisplayed,
    fireCustomScreenDisplayed,
    fireButtonClick,
    fireToggleClick,
    fireHyperLinkClick,
    fireControlDisplayed
  }
}

export default useAnalytics
